import React, { useEffect } from 'react'
import { UserProfile } from 'types/magic'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
// import { useMeQuery } from 'types/graphql'

interface Props {
  user: UserProfile
}

export default function InitLogRocket({ user }: Props): JSX.Element {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
      LogRocket.init('ikg/idnyikueiorg', {
        dom: {
          textSanitizer: false,
          inputSanitizer: true,
        },
      })
      setupLogRocketReact(LogRocket)
    }
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
      if (user?.email) {
        LogRocket.identify(user?.email, {
          email: user?.email,
        })
      }
    }
  }, [user])
  return <></>
}
